import Table from "components/Table";
import Card from "components/card";
import { TABLE_COLUMN_PAYMENT, TABLE_COLUMN_PRODUCT } from "./enum";
import { useCallback, useContext, useEffect } from "react";
import { LayoutContext } from "context/LayoutContext";
import Button from "components/Button";
import { MdOutlineFileUpload } from "react-icons/md";
import { getSaleReport } from "apis/sale_report";
import { removeEmptyValues } from "helper/index";
import { useSalesReport } from "./context/useSalesReport";
import { useToast } from "@chakra-ui/react";
import { ContextSaleReport } from "./context/ContextProvider";
import Filter from "./components/Filter";
import ModalDetail from "./components/ModalWalletDetail";
import { getSaleReportExport } from "apis/export";

const Penjualan = () => {
  const toast = useToast();
  const { setHeaderComponent } = useContext(LayoutContext);
  const {
    loading,
    setLoading,
    tableData,
    setTableData,
    setQuery,
    query,
    detailWallet,
    setDetailWallet,
    names,
    setNames,
  } = useSalesReport();

  const exportWithQuery = useCallback(() => {
    const q = removeEmptyValues(query.watch);
    delete q.page;
    delete q.limit;
    return getSaleReportExport(q);
  }, [query.watch]);

  useEffect(() => {
    setHeaderComponent(
      <div className="flex h-full items-end justify-end gap-3">
        <Button
          label="Export Excel"
          variant="GHOST"
          size="sm"
          onClickHandler={() => exportWithQuery()}
          leftIcon={<MdOutlineFileUpload />}
        />
      </div>
    );
  }, [exportWithQuery]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await getSaleReport(removeEmptyValues(query.watch));

        setTableData(response.data);
        setQuery((prevQuery) => ({
          ...prevQuery,
          total_data: response.total,
          total_page: response.last_page,
        }));
      } catch (err) {
        toast({
          title: "Gagal",
          description: err,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.watch]);

  return (
    <ContextSaleReport.Provider
      value={{
        query,
        setQuery,
        tableData,
        detailWallet,
        setDetailWallet,
        names,
        setNames,
      }}
    >
      <div className="mt-5 !min-h-[80vh]">
        <Card extra={"w-full h-full p-6"}>
          <Filter />
          <div className="mt-8 h-full overflow-x-auto xl:overflow-hidden">
            {loading ? (
              "Loading ..."
            ) : (
              <Table
                columnsData={
                  String(query.watch.type) === "1"
                    ? TABLE_COLUMN_PRODUCT
                    : TABLE_COLUMN_PAYMENT((data) => {
                        setDetailWallet(data);
                      })
                }
                tableData={tableData}
                paginationCallback={(page, offset) => {
                  setQuery((prevQuery) => ({
                    ...prevQuery,
                    watch: { ...prevQuery.watch, page, limit: offset },
                  }));
                }}
                totalData={query.total_data}
                currentPage={query.watch.page}
                perPage={query.watch.limit}
                pageCount={query.total_page}
              />
            )}
          </div>
        </Card>
      </div>
      <ModalDetail />
    </ContextSaleReport.Provider>
  );
};

export default Penjualan;
