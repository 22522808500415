import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import Pagination from "components/Pagination";
import { useEffect, useMemo, useState } from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import emptyTable from "assets/img/empty-table.png";
import Text from "components/Text";

const Table = (props) => {
  const {
    columnsData,
    tableData,
    onTableChange = () => {},
    hidePagination = false,
    paginationCallback = () => {},
    pageCount = 1,
    currentPage = 1,
    perPage = 10,
    totalData = 1,
  } = props;
  const [sorting, setSorting] = useState([]);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = tableData;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useEffect(() => {
    onTableChange({
      sort: table.getState().sorting,
    });
  }, [table.getState().sorting]);

  return (
    <div className="flex flex-grow flex-col">
      <table className={`w-full overflow-auto ${data.length > 0 ? "justify-between" : ""}`}>
        <thead>
          {table.getHeaderGroups().map((headerGroup, index) => (
            <tr key={index}>
              {headerGroup.headers.map((header, index) => (
                <th
                  key={index}
                  className="border-b border-gray-200 bg-backgroundLight px-3 py-4 text-start"
                  style={{ width: header.getSize() }}
                >
                  <div
                    className={`${
                      header.column.getCanSort()
                        ? "cursor-pointer select-none"
                        : ""
                    } flex justify-between`}
                    onClick={header.column.getToggleSortingHandler()}
                    title={
                      header.column.getCanSort()
                        ? header.column.getNextSortingOrder() === "asc"
                          ? "Sort ascending"
                          : header.column.getNextSortingOrder() === "desc"
                          ? "Sort descending"
                          : "Clear sort"
                        : undefined
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <MdArrowDropUp />,
                      desc: <MdArrowDropDown />,
                    }[header.column.getIsSorted()] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="px-3 pb-[18px] pt-[14px]">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex-grow">
        {data.length <= 0 && (
          <div className="flex flex-col items-center justify-center gap-2 p-10">
            <img className="mb-8 mt-8" src={emptyTable} alt="Elon Musk" />
            <Text variant="pBold" content="Data Tidak Ditemukan" />
            <Text variant="p" content="Data yang anda cari tidak ditemukan" />
          </div>
        )}
      </div>
      {!hidePagination && (
        <Pagination
          callback={(page, offset) => paginationCallback(page, offset)}
          pageCount={pageCount}
          currentPage={currentPage}
          perPage={perPage}
          totalData={totalData}
        />
      )}
    </div>
  );
};

export default Table;
