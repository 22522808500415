import React, { useContext } from "react";
import Table from "components/Table";
import { TABLE_COLUMN } from "../config/enum";
import { ContextPurchaseOrder } from "../context/ContextProvider";
import { useFormContext } from "react-hook-form";

const List = () => {
  const { stockOpnameList, table, fetchSO, setOpenCreate } =
    useContext(ContextPurchaseOrder);
  const forms = useFormContext();
  const handleModalOpen = (data, type) => {
    forms.reset({
        ...data,
        po_no: data.no_po,
        created_by: data.user.username,
        so_date: new Date(data.so_date),
        so_details: data.stock_opname_details.map((prd) => ({
            ...prd,
            sku: prd.product.sku,
            capital_price: prd.product.capital_price,
            product_name: prd.product.product_name,
            total_price: prd.qty * prd.price,
            note: prd.notes,
        })),
        formType: type,
    });
    setOpenCreate(true);
  }

  return (
    <div className="mt-8 flex flex-grow overflow-x-auto xl:overflow-hidden">
      <Table
        columnsData={TABLE_COLUMN(
          (data) => handleModalOpen(data, 'detail'),
          (data) => handleModalOpen(data, 'edit'),
        )}
        tableData={stockOpnameList}
        paginationCallback={(page, offset) => fetchSO({ page, limit: offset })}
        currentPage={table.pagination.current_page}
        perPage={table.pagination.per_page}
        pageCount={table.pagination.total}
      />
    </div>
  );
};

export default List;
