import DefaultColumn from "components/Table/components/DefaultColumn";
import { MdRemoveRedEye } from "react-icons/md";
import * as yup from "yup";

const validations = yup.object({
  sales_name: yup.string().required("Nama Sales wajib diisi"),
  id_region: yup.string().required("Wilayah Penjualan wajib diisi"),
  id_sales_role: yup.string().required("Role wajib diisi"),
  email: yup
    .string()
    .email()
    .when("id_sales_role", {
      is: "1",
      then: () => yup.string().required("Email wajib diisi"),
    }),
  password: yup.string().when("id_sales_role", {
    is: "1",
    then: () => yup.string().required("Kata Sandi wajib diisi"),
  }),
  confirm_password: yup.string().when("id_sales_role", {
    is: "1",
    then: () =>
      yup
        .string()
        .required("Kata Sandi wajib diisi")
        .oneOf([yup.ref("password")], "Kata Sandi tidak sama"),
  }),
  pin: yup.string().when("id_sales_role", {
    is: "2",
    then: () => yup.string().required("PIN wajib diisi"),
  }),
  confirm_pin: yup.string().when("id_sales_role", {
    is: "2",
    then: () =>
      yup
        .string()
        .required("PIN wajib diisi")
        .oneOf([yup.ref("pin")], "PIN tidak sama"),
  }),
});

const TABLE_COLUMN = (onDetail) => [
  {
    accessorKey: "customer_name",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    header: "Nama Toko",
  },
  {
    accessorKey: "user_sale.sales_name",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    header: "Nama Sales",
  },
  {
    accessorKey: "region.region_name",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    header: "Wilayah Penjualan",
  },
  {
    accessorKey: "action",
    header: "Aksi",
    size: 125,
    cell: (info) => (
      <button
        className="flex h-8 w-8 items-center justify-center rounded-lg bg-[#BEDBFE] text-[#3A65FF]"
        onClick={() => {
          onDetail(info.row.original.id_customer);
        }}
      >
        <MdRemoveRedEye />
      </button>
    ),
    enableSorting: false,
  },
];

export { TABLE_COLUMN, validations };
