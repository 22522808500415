import ModalContainer from "components/Modal";
import moment from "moment";
import React, { useState } from "react";
import { MdCheckCircleOutline, MdRemoveRedEye } from "react-icons/md";
import ModalDetailTransaction from "./modal-detail-transaction";
import ModalDetailCheckin from "./modal-detail-checkin";

const AccordionTransaction = (props) => {
  const { data, setTransaction, setCheckin } = props;
  return (
    <div className="relative">
      <div className="absolute left-[9px] top-[50px] h-[calc(100%-100px)] w-[2px] bg-gray-200"></div>

      {data?.map((item, index) => {
        const bg =
          item.data_transaction?.is_paid === 1 ? "bg-green-500" : "bg-gray-400";
        return (
          <div key={index} className="flex items-center gap-4 py-3">
            <div className="relative">
              <div className="relative z-10 bg-white py-1 text-green-500">
                <MdCheckCircleOutline className="h-5 w-5" />
              </div>
            </div>
            <div className="w-[500px] rounded-xl border-[1px] border-[#e0e0e0] p-4 hover:bg-[#fafafa]">
              <div className="flex items-center justify-between">
                <div>
                  <div className="flex flex-col items-start leading-none">
                    <div className="flex items-center gap-2">
                      <span className="font-medium">
                        {
                          item?.[
                            item.type === 2
                              ? "data_transaction"
                              : "data_checkin"
                          ]?.customer?.customer_name
                        }{" "}
                      </span>
                      {item.type === 2 && (
                        <span
                          className={`rounded-full ${bg} px-3 py-0.5 text-xs text-white`}
                        >
                          {item.data_transaction?.is_paid === 1
                            ? "Lunas"
                            : "Belum Lunas"}
                        </span>
                      )}
                      <span
                        className={`rounded-full bg-teal-400 px-3 py-0.5 text-xs text-white`}
                      >
                        {item.type_name}
                      </span>
                    </div>
                    <div className="text-xs text-gray-600">
                      {moment(item.timestamp).format("DD MMMM YYYY")}
                    </div>
                  </div>
                  {item.type === 2 && (
                    <div className="mt-2 flex items-baseline">
                      <span className="text-sm font-bold">Total : </span>
                      <div className="ml-2 text-sm text-gray-600">
                        Rp. {item?.data_transaction?.total || "-"}
                      </div>
                    </div>
                  )}
                  {item.type === 1 && (
                    <div className="flex items-baseline">
                      <span className="text-sm font-bold">Alamat : </span>
                      <div className="ml-2 text-sm text-gray-600">
                        {item?.data_checkin?.customer?.address || ""}
                        {", "}
                        {item?.data_checkin?.customer?.customer_name}
                      </div>
                    </div>
                  )}
                </div>
                <button
                  onClick={() => {
                    if (item.type === 2)
                      setTransaction({
                        show: true,
                        data: item.data_transaction,
                      });
                    else
                      setCheckin({
                        show: true,
                        data: item.data_checkin,
                      });
                  }}
                  className="flex h-8 w-8 items-center justify-center rounded-lg bg-[#BEDBFE] text-[#3A65FF]"
                >
                  <MdRemoveRedEye />
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ModalShowMore = ({ open, onClose, data }) => {
  const [transaction, setTransaction] = useState({ show: false, data: null });
  const [checkin, setCheckin] = useState({ show: false, data: null });

  return (
    <>
      <ModalContainer
        isOpen={open}
        modalTitle={`List Laporan KPI Sales`}
        onClose={onClose}
        hideButton
      >
        <AccordionTransaction
          data={data}
          setTransaction={setTransaction}
          setCheckin={setCheckin}
        />
      </ModalContainer>
      <ModalDetailTransaction
        open={transaction.show}
        onClose={() => setTransaction({ show: false, data: null })}
        detailData={transaction.data}
      />
      <ModalDetailCheckin
        open={checkin.show}
        onClose={() => setCheckin({ show: false, data: null })}
        detailData={checkin.data}
      />
    </>
  );
};

export default ModalShowMore;
