import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import React, { useState, useRef, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import moment from "moment";

const Index = (props) => {
  const { onChange, value: v } = props;
  const currentDate = [v];

  const [tempDate, setTempDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [popupPosition, setPopupPosition] = useState("left-0");
  const wrapperRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (showDatePicker && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const datePickerWidth = 700;
      const windowWidth = window.innerWidth;

      if (buttonRect.left + 200 + datePickerWidth > windowWidth) {
        setPopupPosition("right-0");
      } else {
        setPopupPosition("left-0");
      }
    }
  }, [showDatePicker]);

  const formatDateRange = (dateRange) => {
    const { startDate, endDate } = dateRange;
    if (!startDate) {
      return "Pilih tanggal";
    }

    const start = moment(startDate).format("MM/DD/YYYY");
    const end = moment(endDate).format("MM/DD/YYYY");

    if (start === end) {
      return start;
    }
    return `${start} - ${end}`;
  };

  const handleApplyDate = () => {
    onChange(tempDate[0]);
    setShowDatePicker(false);
  };

  const handleCancelDate = () => {
    setTempDate(currentDate);
    setShowDatePicker(false);
  };

  const resetFilter = () => {
    setTempDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  return (
    <div className="relative">
      <div
        ref={buttonRef}
        className="flex min-h-[48px] w-full cursor-pointer items-center justify-start rounded-full border bg-white"
        onClick={() => setShowDatePicker(!showDatePicker)}
      >
        <span className="pl-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="16" y1="2" x2="16" y2="6"></line>
            <line x1="8" y1="2" x2="8" y2="6"></line>
            <line x1="3" y1="10" x2="21" y2="10"></line>
          </svg>
        </span>
        <span className="ml-2">{formatDateRange(currentDate[0])}</span>
      </div>

      {/* Date Picker Popup */}
      {showDatePicker && (
        <div
          ref={wrapperRef}
          className={`absolute z-50 mt-1 rounded border bg-white shadow-lg ${popupPosition}`}
          style={{ maxWidth: "100vw" }}
        >
          <DateRangePicker
            onChange={(item) => setTempDate([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            className="font-poppins"
            staticRanges={[
              {
                label: "Hari ini",
                range: () => ({
                  startDate: moment().startOf("day").toDate(),
                  endDate: moment().endOf("day").toDate(),
                }),
                isSelected() {
                  return false;
                },
              },
              {
                label: "Kemarin",
                range: () => ({
                  startDate: moment()
                    .subtract(1, "day")
                    .startOf("day")
                    .toDate(),
                  endDate: moment().subtract(1, "day").endOf("day").toDate(),
                }),
                isSelected() {
                  return false;
                },
              },
              {
                label: "Minggu ini",
                range: () => ({
                  startDate: moment().startOf("week").toDate(),
                  endDate: moment().endOf("week").toDate(),
                }),
                isSelected() {
                  return false;
                },
              },
              {
                label: "Minggu lalu",
                range: () => ({
                  startDate: moment()
                    .subtract(1, "weeks")
                    .startOf("week")
                    .toDate(),
                  endDate: moment().subtract(1, "weeks").endOf("week").toDate(),
                }),
                isSelected() {
                  return false;
                },
              },
              {
                label: "Bulan ini",
                range: () => ({
                  startDate: moment().startOf("month").toDate(),
                  endDate: moment().endOf("month").toDate(),
                }),
                isSelected() {
                  return false;
                },
              },
              {
                label: "Bulan lalu",
                range: () => ({
                  startDate: moment()
                    .subtract(1, "month")
                    .startOf("month")
                    .toDate(),
                  endDate: moment()
                    .subtract(1, "month")
                    .endOf("month")
                    .toDate(),
                }),
                isSelected() {
                  return false;
                },
              },
              {
                label: "3 Bulan lalu",
                range: () => ({
                  startDate: moment()
                    .subtract(2, "months")
                    .startOf("month")
                    .toDate(),
                  endDate: moment().toDate(),
                }),
                isSelected() {
                  return false;
                },
              },
            ]}
            months={2}
            ranges={tempDate}
            inputRanges={[]}
            direction="horizontal"
          />
          <div className="flex items-center justify-between border-t p-3">
            <div>
              <button
                onClick={resetFilter}
                className="mr-2 rounded bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300"
              >
                Reset Filter
              </button>
            </div>
            <div>
              <button
                onClick={handleCancelDate}
                className="mr-2 rounded bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300"
              >
                Batal
              </button>
              <button
                onClick={handleApplyDate}
                className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
              >
                Terapkan
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
