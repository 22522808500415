import InputDateRange from "components/Form/InputDateRange/v1";
import InputSearch from "components/Form/InputSearch";
import { useContext, useEffect, useState } from "react";
import { ContextHistoryStock } from "../context/ContextProvider";
import moment from "moment";
import { debounce } from "helper/index";

const Filter = () => {
  const { setQuery } = useContext(ContextHistoryStock);
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
    key: "selection",
  });

  const { startDate, endDate } = dateRange;

  useEffect(() => {
    if (startDate && endDate) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        watch: {
          ...prevQuery.watch,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        },
      }));
    }
  }, [startDate, endDate]);

  return (
    <div className="relative flex items-center gap-3">
      <div className="flex-grow">
        <InputSearch
          changeHandler={debounce(
            (e) =>
              setQuery((prevQuery) => ({
                ...prevQuery,
                watch: { ...prevQuery.watch, keyword: e.target.value, page: 1 },
              })),
            500
          )}
          rounded
        />
      </div>
      <div className="flex-1">
        <InputDateRange
          onChange={(update) => setDateRange(update)}
          value={dateRange}
        />
      </div>
    </div>
  );
};

export default Filter;
