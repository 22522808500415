import InputDateRange from "components/Form/InputDateRange/v1";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { getSales } from "apis/sales";
import { useContext, useEffect, useState } from "react";
import { ContextTransaction } from "../context/ContextProvider";
import moment from "moment";
import { getGudang } from "apis/gudang";

const Filter = () => {
  const { query, setQuery } = useContext(ContextTransaction);

  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
    key: "selection",
  });
  const { startDate, endDate } = dateRange;

  useEffect(() => {
    if (startDate && endDate) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        watch: {
          ...prevQuery.watch,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          page: 1,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <div className="mt-3 flex items-end gap-5">
      <div className="w-1/3">
        <InputMultipleSelect
          fetchList={getSales}
          keyLabel="sales_name"
          keyValue="id_sales"
          label="Pilih sales"
          initialLabel="Semua Sales"
          onChange={(value) =>
            setQuery((prevQuery) => ({
              ...prevQuery,
              watch: { ...prevQuery.watch, id_sales: value, page: 1 },
            }))
          }
          value={query.watch.id_sales}
        />
      </div>
      <div className="w-1/3">
        <InputMultipleSelect
          fetchList={getGudang}
          noLimit
          keyLabel="gudang_name"
          label="Pilih gudang"
          keyValue="id_gudang"
          initialLabel="Semua Gudang"
          onChange={(value) => {
            setQuery((prevQuery) => ({
              ...prevQuery,
              watch: { ...prevQuery.watch, id_gudang: value },
            }));
          }}
          value={query.watch.id_gudang}
          rounded
        />
      </div>
      <div className="w-1/3">
        <InputDateRange
          onChange={(update) => setDateRange(update)}
          value={dateRange}
        />
      </div>
    </div>
  );
};

export default Filter;
