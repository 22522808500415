import InputSelect from "components/Form/InputSelect";
import { LayoutContext } from "context/LayoutContext";
import React, { useContext, useEffect, useState } from "react";
import AccordionTransaction from "./_components/accordion-trx";
import { getDetailKpiSales } from "apis/detail_kpi_sales";
import moment from "moment";
import InputDateRange from "components/Form/InputDateRange/v1";
import { useParams } from "react-router-dom";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { getCustomers } from "apis/customers";

const Index = () => {
  const { setHeaderComponent } = useContext(LayoutContext);
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
    key: "selection",
  });
  const [idCustomer, setIdCustomer] = useState(null);
  const { id } = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setHeaderComponent([]), []);

  useEffect(() => {
    (async () => {
      try {
        let query = `?start_date=${moment(dateRange.startDate).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(dateRange.endDate).format("YYYY-MM-DD")}`;

        if (idCustomer) {
          query += `&id_customer=${idCustomer}`;
        }

        const resp = await getDetailKpiSales(
          {
            id,
          },
          query
        );

        setInfo(resp);
      } catch (error) {
        console.error("Error when fetch : ", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 700);
      }
    })();
  }, [id, dateRange.startDate, dateRange.endDate, idCustomer]);

  return (
    <>
      <div className="mt-6 flex items-end justify-end gap-4">
        <div className="w-1/5">
          {loading ? (
            <div class="h-12 w-full animate-pulse rounded-full bg-gray-200" />
          ) : (
            <InputMultipleSelect
              fetchList={getCustomers}
              rounded
              keyLabel="customer_name"
              keyValue="id_customer"
              label="Pilih pelanggan"
              value={idCustomer}
              onChange={(e) => {
                setIdCustomer(e);
              }}
            />
          )}
        </div>
        <div className="w-1/5">
          {loading ? (
            <div class="h-12 w-full animate-pulse rounded-full bg-gray-200" />
          ) : (
            <InputDateRange onChange={setDateRange} value={dateRange} />
          )}
        </div>
      </div>
      {loading ? (
        <div class="my-6 h-40 w-full animate-pulse rounded-xl bg-gray-200" />
      ) : (
        <div className="my-6 rounded-xl bg-white p-6 shadow-sm">
          <div className="flex items-start justify-between">
            <div className="flex items-start gap-6">
              {/* <div className="relative h-24 w-24">
              <div className="absolute inset-0">
                <svg className="h-full w-full" viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    fill="none"
                    stroke="#e5e7eb"
                    strokeWidth="12"
                  />
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    fill="none"
                    stroke="#84cc16"
                    strokeWidth="12"
                    strokeDasharray="251.2"
                    strokeDashoffset="150.72"
                    transform="rotate(-90 50 50)"
                  />
                </svg>
              </div>
              <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xs font-bold sm:text-lg">
                40%
              </span>
            </div> */}

              <div>
                <div className="text-sm text-gray-600">
                  {info?.email || "-"}
                </div>
                <h1 className="mt-1 text-xl font-semibold">
                  {info?.sales_name}
                </h1>
                <div className="mt-1 text-sm text-gray-600">
                  {info?.phone || "-"}
                </div>

                <div className="mt-3 space-y-1">
                  <div className="text-sm">
                    <span className="text-gray-600">Periode: </span>
                    <span>
                      {moment(dateRange.startDate).format("DD MMMM YYYY")} -{" "}
                      {moment(dateRange.endDate).format("DD MMMM YYYY")}
                    </span>
                  </div>
                  {/* <div className="text-sm">
                    <span className="text-gray-600">Outlet: </span>
                    <span>Outlet Malang</span>
                  </div> */}
                </div>
              </div>
            </div>

            {/* <div className="text-right">
            <div className="inline-block rounded-full bg-blue-50 px-3 py-1 text-sm text-blue-600">
              Profit
            </div>
            <div className="mt-4 text-2xl font-bold">Rp40.750</div>
            <div className="mt-2">
              <span className="bg-emerald-600 rounded-full px-3 py-1 text-sm text-white">
                Lunas
              </span>
            </div>
          </div> */}
          </div>
        </div>
      )}
      {loading ? (
        <div class="h-40 w-full animate-pulse rounded-xl bg-gray-200" />
      ) : (
        <>
          <h2 className="mb-4 text-lg font-semibold">List Aktivitas</h2>
          <div className="flex flex-col gap-2">
            {info?.list_activity?.map((act) => (
              <div
                className="rounded-xl bg-white p-6 shadow-sm"
                key={act.activity_date}
              >
                <div className="mb-6 flex items-start justify-between">
                  <div>
                    <h2 className="text-xl font-bold">
                      {moment(act.activity_date).format("DD MMMM YYYY")}
                    </h2>
                  </div>
                </div>

                <div className="border-t pt-4">
                  <AccordionTransaction data={act.data} />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default Index;
