import { LayoutContext } from "context/LayoutContext";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

const BreadCrumb = (props) => {
  const { brandText, childText } = props;
  const { headerComponent } = useContext(LayoutContext);

  return (
    <div className="z-30 flex justify-between px-8 pt-4">
        <div className="w-1/2">
            <p className="shrink text-[33px] capitalize text-textDefault">
                <Link
                    to="#"
                    className="font-bold capitalize hover:text-textDefault"
                >
                    {childText || brandText}
                </Link>
            </p>
            <div className="h-6 w-[254px] pt-1">
                <a
                    className="text-sm font-normal text-textDefault hover:underline"
                    href=" "
                >
                    {brandText}
                    <span className="mx-1 text-sm text-textDefault hover:text-textDefault">
                        {" "}
                        /{" "}
                    </span>
                </a>
                <Link
                    className="text-sm font-normal capitalize text-textDefault hover:underline"
                    to="#"
                >
                    {childText || brandText}
                </Link>
            </div>
        </div>
        <div className="w-1/2">
            {headerComponent}
        </div>
    </div>
  );
};

export default BreadCrumb;
