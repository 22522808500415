import Table from "components/Table";
import Card from "components/card";
import { TABLE_COLUMN } from "./enum";
import Button from "components/Button";
import { MdOutlineFileUpload } from "react-icons/md";
import InputSelect from "components/Form/InputSelect";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import InputSearch from "components/Form/InputSearch";
import { getLocations } from "apis/locations";
import { useToast } from "@chakra-ui/react";
import { getSales } from "apis/sales";
import { debounce } from "helper/index";
import { getUserSalesExport } from "apis/export";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { getUsers } from "apis/users";
import { useNavigate } from "react-router-dom";

const KpiSales = () => {
  const navigate = useNavigate();
  const { setHeaderComponent } = useContext(LayoutContext);
  const [locationList, setLocationList] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [table, setTable] = useState({
    loading: false,
    pagination: { total: 1, current_page: 1, per_page: 10 },
  });
  const [query, setQuery] = useState({
    id_user: null,
    id_region: null,
    keyword: "",
  });

  const toast = useToast();

  const onTableChange = (tableState) => {
    console.log(tableState);
  };

  const fetchLocations = async () => {
    try {
      const res = await getLocations({ limit: 10, page: 1 });

      const formattedData = res.map((item) => ({
        value: item.id_region,
        label: item.region_name,
      }));
      setLocationList([{ value: "", label: "Pilih Area" }, ...formattedData]);
    } catch (error) {
      toast({
        title: "Gagal mendapatkan data lokasi",
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  };

  const fetchSales = async (params = {}) => {
    try {
      const res = await getSales(params);
      setSalesList(res.data);
      setTable((prev) => ({
        ...prev,
        pagination: {
          total: res.last_page,
          per_page: res.per_page,
          current_page: res.current_page,
        },
      }));
    } catch (error) {
      toast({
        title: "Gagal mendapatkan data sales",
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    fetchLocations();
    fetchSales({ page: 1, limit: 10 });
  }, []);

  const exportWithQuery = useCallback(() => {
    return getUserSalesExport({
      keyword: query.search,
      id_region: query.id_region,
    });
  }, [query.id_region, query.search]);

  useEffect(() => {
    setHeaderComponent(
      <div className="flex h-full items-end justify-end gap-3">
        <Button
          onClickHandler={() => exportWithQuery()}
          label="Export Excel"
          variant="GHOST"
          size="sm"
          leftIcon={<MdOutlineFileUpload />}
        />
      </div>
    );
  }, []);

  const hasQuery = Object.values(query).some((p) => p);

  useEffect(() => {
    if (hasQuery) {
      fetchSales({ page: 1, limit: 10, ...query });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasQuery, query]);

  return (
    <Card
      extra={
        "w-full h-full p-6 sm:overflow-x-auto flex-grow mt-5 flex flex-col"
      }
    >
      <div className="relative flex items-end gap-3">
        <div className="flex-grow">
          <InputSearch
            rounded
            changeHandler={debounce(
              (e) =>
                setQuery((prevQuery) => ({
                  ...prevQuery,
                  keyword: e.target.value,
                })),
              500
            )}
          />
        </div>
        <div className="w-1/5">
          <InputMultipleSelect
            fetchList={getSales}
            keyLabel="sales_name"
            noLimit
            label="Pilih sales"
            initialLabel="Semua sales"
            keyValue="id_sales"
            onChange={(value) => {
              setQuery((prevQuery) => ({
                ...prevQuery,
                id_user: value,
              }));
            }}
            value={query.id_user}
            rounded
          />
        </div>
        <div className="w-1/5">
          <InputSelect
            optionList={locationList}
            rounded
            label="Pilih area"
            onChange={(e) =>
              setQuery((prevQuery) => ({
                ...prevQuery,
                id_region: e.target.value,
              }))
            }
          />
        </div>
      </div>

      <div className="mt-8 flex flex-grow overflow-x-scroll xl:overflow-hidden">
        <Table
          columnsData={TABLE_COLUMN((id) =>
            navigate(`/admin/laporan/kpi-sales/${id}/detail`)
          )}
          tableData={salesList}
          onTableChange={onTableChange}
          paginationCallback={(page, offset) => {
            if (hasQuery) {
              fetchSales({ page, limit: offset, ...query });
            } else {
              fetchSales({ page, limit: offset });
            }
          }}
          currentPage={table.pagination.current_page}
          perPage={table.pagination.per_page}
          pageCount={table.pagination.total}
        />
      </div>
    </Card>
  );
};

export default KpiSales;
