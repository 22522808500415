import { useContext, useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import Card from "components/card";
import Button from "components/Button";
import { LayoutContext } from "context/LayoutContext";
import List from "./component/List";
import Filter from "./component/Filter";
import { ContextPurchaseOrder } from "./context/ContextProvider";
import ModalCreate from "./component/ModalCreate";
import ModalProduct from "./component/ModalProduct";
import { useToast } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultFieldValues, validations } from "./config/enum";
import { removeEmptyValues } from "helper/index";
import { usePurchaseOrderList } from "./context/usePurchaseOrderList";
import { usePurchaseStockForm } from "./context/usePurchaseStockForm";
import { getLocations } from "apis/locations";
import { getTransferStockgudang } from "apis/trf_stock_gudang";
import moment from "moment";

const StockOpname = () => {
  const { setHeaderComponent } = useContext(LayoutContext);
  const { openCreate, setOpenCreate } = usePurchaseOrderList();
  const { openProduct, setOpenProduct } = usePurchaseStockForm();

  // Start context state
  const [stockOpnameList, setStockOpnameList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [table, setTable] = useState({
    loading: false,
    pagination: { total: 1, current_page: 1, per_page: 10 },
  });
  const [query, setQuery] = useState({
    watch: {
      keyword: "",
      start_date: moment().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),
    },
  });
  // End context state

  const toast = useToast();

  const forms = useForm({
    defaultValues: defaultFieldValues,
    resolver: yupResolver(validations),
  });

  const fetchLocations = async () => {
    try {
      const res = await getLocations({ limit: 20, page: 1 });

      const formattedData = res.map((item) => ({
        value: item.id_region.toString(),
        label: item.region_name,
      }));
      setLocationList([{ value: "", label: "Pilih Area" }, ...formattedData]);
    } catch (error) {
      toast({
        title: "Gagal mendapatkan data lokasi",
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  };

  const fetchSO = async (params = {}) => {
    try {
      const res = await getTransferStockgudang(removeEmptyValues(params));
      setStockOpnameList(res.data);
      setTable((prev) => ({
        ...prev,
        pagination: {
          total: res.last_page,
          per_page: res.per_page,
          current_page: res.current_page,
        },
      }));
    } catch (error) {
      toast({
        title: "Gagal mendapatkan data sales",
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  };

  useEffect(() => {
    fetchLocations();
    setHeaderComponent(
      <div className="flex h-full items-end justify-end gap-3">
        {/* <Button
          label="Export Excel"
          variant="GHOST"
          size="sm"
          leftIcon={<MdOutlineFileUpload />}
        /> */}
        <Button
          label="Tambah Transfer Stok Gudang"
          variant="DEFAULT"
          size="sm"
          leftIcon={<MdAdd />}
          onClickHandler={() => setOpenCreate(true)}
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSO({
      page: 1,
      limit: 10,
      ...query.watch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.watch]);

  return (
    <ContextPurchaseOrder.Provider
      value={{
        openCreate,
        setOpenCreate,
        openProduct,
        setOpenProduct,
        stockOpnameList,
        query,
        setQuery,
        table,
        fetchSO,
        toast,
        isEdit: forms.watch("formType") === "edit",
        isDetail: forms.watch("formType") === "detail",
        locationList,
      }}
    >
      <FormProvider {...forms}>
        <Card extra="w-full h-full p-6 flex-grow mt-5 flex flex-col">
          <Filter />

          <List />

          <ModalCreate />

          <ModalProduct />
        </Card>
      </FormProvider>
    </ContextPurchaseOrder.Provider>
  );
};

export default StockOpname;
