import { MdArrowDropDown } from "react-icons/md";
import Text from "components/Text";
import { Fragment, useEffect, useRef, useState } from "react";
import InputSearch from "../InputSearch";
import Checkbox from "components/checkbox";
import { debounce } from "helper/index";

const InputMultipleSelect = ({
  label = "",
  rounded = false,
  hideSearch = false,
  optionList = [],
  onChange = () => {},
  value: v = [],
  fetchList = () => {},
  keyLabel = "label",
  keyValue = "value",
  menuClassname = "",
  initialLabel = "",
  required = false,
  errors = { show: false, message: "" },
  disabled,
  isMultiple = false,
  getLabels = null,
  initLabels = [],
  noLimit = false,
}) => {
  const value = isMultiple ? (Array.isArray(v) ? v : [v]) : v;
  const [openSelect, setOpenSelect] = useState(false);
  const [options, setOptions] = useState(optionList);
  const [names, setNames] = useState([]);
  const [search, setSearch] = useState("");
  const [title, setTitle] = useState(initialLabel);
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenSelect(false);
      setSearch("");
    }
  };

  useEffect(() => {
    setTitle(initialLabel);
  }, [initialLabel]);

  useEffect(() => {
    if (getLabels) getLabels(names);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [names.length]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchList({
          keyword: search,
          limit: noLimit ? 200 : 10,
        });

        setOptions(
          (response.data || response).map((t) => ({
            label: t[keyLabel],
            value: t[keyValue],
            ...t,
          }))
        );
      } catch (err) {
        console.log("Error when fetching data select : ", err);
      }
    };

    openSelect && fetchList && fetchData();
  }, [search, openSelect]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="relative flex flex-col gap-1" ref={ref}>
      {label && (
        <Text
          required={required}
          variant="linkBold"
          customStyle="mb-[6px]"
          content={label}
        />
      )}
      <div
        className={`relative inline-block flex w-full items-center ${
          rounded ? "rounded-full" : "rounded-xl"
        } ${errors.show ? "border-[#DF2A36]" : "border-gray-200"} ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        } border bg-white px-4 py-3 outline-none`}
        onClick={() => {
          if (disabled) return;

          setOpenSelect(!openSelect);
        }}
      >
        <Text variant="p" content={title || "Semua"} />
        <div
          className={`pointer-events-none absolute inset-y-0 right-0 flex items-center px-2`}
        >
          <MdArrowDropDown />
        </div>
      </div>
      {openSelect && (
        <div
          className={
            menuClassname +
            "absolute top-12 z-[99999999] flex w-full shadow-xl shadow-shadow-500"
          }
        >
          <div className="max-h-[400px] w-full flex-col overflow-y-auto bg-white p-4">
            {!hideSearch && (
              <Fragment>
                <InputSearch
                  rounded
                  changeHandler={debounce(
                    (e) => setSearch(e.target.value),
                    500
                  )}
                />
                <div className="my-3 border-b-[1px] border-[#E4E5E8]"></div>
              </Fragment>
            )}
            {options?.map((x) => (
              <div key={x.value} className="mb-3 flex flex-col">
                <Checkbox
                  id={x.value}
                  label={x.label}
                  disabled={disabled}
                  onChange={() => {
                    if (isMultiple) {
                      if (value?.includes(x.value)) {
                        setTitle("Pilih");

                        setNames((prev) =>
                          prev.filter((name) => x.label !== name)
                        );

                        return onChange(
                          value.filter((v) => v !== x.value),
                          options.filter((o) =>
                            [...value, x.value].includes(o.value)
                          )
                        );
                      }

                      setNames((prev) => [
                        ...prev,
                        ...options
                          .filter((opt) => opt.value === x.value)
                          .map((t) => t.label),
                      ]);
                      setTitle("Pilih");

                      return onChange(
                        [...value, x.value],
                        options.filter((o) =>
                          [...value, x.value].includes(o.value)
                        )
                      );
                    }

                    onChange(
                      x.value === value ? "" : x.value,
                      x.value === value ? "" : x.label,
                      x.value === value ? "" : x
                    );
                    setTitle(x.value === value ? "" : x.label);
                  }}
                  checked={
                    isMultiple ? value?.includes(x.value) : x.value === value
                  }
                />
                <div className="mt-3 border-b-[1px] border-[#E4E5E8]"></div>
              </div>
            ))}
          </div>
        </div>
      )}
      {errors.show && (
        <span className="text-[12px] text-[#DF2A36]">{errors.message}</span>
      )}
    </div>
  );
};

export default InputMultipleSelect;
