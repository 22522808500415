import httpService from "services/api.service";

export const getUsers = async (params) => {
  try {
    const res = await httpService.get("/users", { params });

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const createUser = async (payload) => {
  try {
    const res = await httpService.post("/create-user", payload);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const updateUser = async (payload) => {
  try {
    const res = await httpService.put(`/update-user`, payload);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const deleteUser = async (payload) => {
  try {
    const res = await httpService.delete(`/delete-user/${payload.id}`);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};
