import Table from "components/Table";
import Card from "components/card";
import { TableColumn } from "./enum";
import Button from "components/Button";
import { MdAdd, MdOutlineFileUpload } from "react-icons/md";
import InputSelect from "components/Form/InputSelect";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import InputSearch from "components/Form/InputSearch";
import Modal from "components/Modal";
import { useToast } from "@chakra-ui/react";
import SwitchField from "components/fields/SwitchField";
import InputField from "components/fields/InputField";
import { useForm } from "react-hook-form";
import {
  createProductCategory,
  deleteProductCategory,
  getProductCategory,
  updateProductCategory,
} from "apis/product_category";
import { debounce } from "helper/index";
import { yupResolver } from "@hookform/resolvers/yup";
import { validations } from "./utils";
import { getErrorField } from "helper/geterrorfield";
import { createUser, deleteUser, getUsers, updateUser } from "apis/users";
import { getLocations } from "apis/locations";

const DataUser = () => {
  const { setHeaderComponent } = useContext(LayoutContext);
  const forms = useForm({
    defaultValues: { username: "" },
    resolver: yupResolver(validations),
  });
  const [locationList, setLocationList] = useState([]);

  const fetchLocations = async () => {
    try {
      const res = await getLocations({ limit: 20, page: 1 });

      const formattedData = res.map((item) => ({
        value: item.id_region.toString(),
        label: item.region_name,
      }));
      setLocationList([{ value: "", label: "Pilih Area" }, ...formattedData]);
    } catch (error) {
      toast({
        title: "Gagal mendapatkan data lokasi",
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  };

  const [isOpenForm, setIsOpenForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [table, setTable] = useState({
    loading: false,
    data: [],
    pagination: {
      total_data: 1,
      total_page: 1,
      current_page: 1,
      per_page: 10,
      status: "",
      keyword: "",
    },
  });

  const fetchTable = async (params = {}) => {
    const response = await getUsers(params);

    setTable((prev) => ({
      ...prev,
      data: response,
      pagination: {
        total_page: 1,
        total_data: response.length,
        per_page: 10,
        current_page: 1,
        status: params.status,
        keyword: params.keyword,
      },
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchTable();
      } catch (err) {
        toast({
          title: "Gagal",
          description: err,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      }
    };

    fetchData();
    fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toast = useToast();

  const handleSubmit = forms.handleSubmit(async (values) => {
    try {
      if (values.id) {
        await updateUser({ ...values, id_user: values.id });
      } else {
        await createUser(values);
      }

      setIsOpenForm(false);

      forms.reset({});

      fetchTable({ page: 1, limit: 10 });

      toast({
        title: "Berhasil",
        description: `Sukses ${
          values.id ? "mengedit" : "menambahkan"
        } user`,
        status: "success",
        isClosable: true,
        position: "top-right",
      });
    } catch (err) {
      toast({
        title: "Gagal",
        description: err,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  });

  const onTableChange = (tableState) => {
    console.log(tableState);
  };

  useEffect(() => {
    setHeaderComponent(
      <div className="flex h-full items-end justify-end gap-3">
        {/* <Button
          label="Export Excel"
          variant="GHOST"
          size="sm"
          leftIcon={<MdOutlineFileUpload />}
        /> */}
        <Button
          label="Tambah User"
          variant="DEFAULT"
          size="sm"
          leftIcon={<MdAdd />}
          onClickHandler={() => {
            setIsOpenForm(!isOpenForm);
          }}
        />
      </div>
    );
  }, [setIsOpenForm, isOpenForm]);

  return (
    <Card
      extra={
        "w-full h-full p-6 sm:overflow-x-auto flex-grow mt-5 flex flex-col"
      }
    >
      <div className="relative flex items-end gap-3">
        <div className="flex-grow">
          <InputSearch
            rounded
            changeHandler={debounce(
              (e) =>
                fetchTable({
                  keyword: e.target.value,
                  page: 1,
                  limit: 10,
                  status: table.pagination.status,
                }),
              500
            )}
          />
        </div>
      </div>

      <div className="mt-8 flex flex-grow overflow-x-scroll xl:overflow-hidden">
        <Table
          columnsData={TableColumn(
            (data) => {
              forms.setValue("username", data.username);
              forms.setValue("id", data.id_user);
              forms.setValue("id_region", data.id_region);
              setIsOpenForm(true);
            },
            (data) => {
              forms.setValue("id", data.id_user);
              setDeleteModal(true);
            }
          )}
          tableData={table.data}
          paginationCallback={(page, offset) =>
            fetchTable({ page, limit: offset })
          }
          totalData={table.pagination.total_data}
          currentPage={table.pagination.current_page}
          perPage={table.pagination.per_page}
          pageCount={table.pagination.total_page}
          onTableChange={onTableChange}
        />
      </div>
      <Modal
        isOpen={deleteModal}
        modalTitle="Hapus User"
        onClose={() => {
          forms.reset({ username: "" });
          setDeleteModal(false);
        }}
        isSubmitting={forms.formState.isSubmitting}
        onSubmit={async () => {
          await deleteUser({ id: forms.watch("id") });

          await fetchTable({ page: table.pagination.current_page, limit: 10 });

          toast({
            title: "Berhasil",
            description: `Sukses menghapus user`,
            status: "success",
            isClosable: true,
            position: "top-right",
          });

          setDeleteModal(false);
        }}
      >
        Apakah anda yakin ingin menghapus user ini?
      </Modal>
      <Modal
        isOpen={isOpenForm}
        modalTitle={`${forms.watch("id") ? "Edit" : "Tambah"} User`}
        onClose={() => {
          forms.reset({ username: "", status: 0, id: null });
          setIsOpenForm(!isOpenForm);
        }}
        isSubmitting={forms.formState.isSubmitting}
        onSubmit={handleSubmit}
      >
        <div className="flex w-[450px] flex-col gap-4">
          <InputField
            register={forms.register("username")}
            label="Username"
            required
            state={
              getErrorField(forms.formState.errors, "username").show
                ? "error"
                : ""
            }
            errors={getErrorField(forms.formState.errors, "username")}
          />
          {forms.watch("id") ? (
            <>
              <InputField
                label="Password Lama"
                register={forms.register("old_password")}
                state={
                  getErrorField(forms.formState.errors, "old_password").show
                    ? "error"
                    : ""
                }
                errors={getErrorField(forms.formState.errors, "old_password")}
                type="password"
              />
              <InputField
                label="Password Baru"
                register={forms.register("new_password")}
                state={
                  getErrorField(forms.formState.errors, "new_password").show
                    ? "error"
                    : ""
                }
                errors={getErrorField(forms.formState.errors, "password")}
                type="password"
              />
            </>
          ) : (
            <InputField
              label="Password"
              register={forms.register("password")}
              state={
                getErrorField(forms.formState.errors, "password").show
                  ? "error"
                  : ""
              }
              errors={getErrorField(forms.formState.errors, "password")}
              type="password"
            />
          )}
          <InputSelect
            optionList={locationList}
            label="Area/Kota"
            register={forms.register("id_region")}
            onChange={(e) =>
              forms.setValue("id_region", e.target.value.toString())
            }
            required
            state={
              getErrorField(forms.formState.errors, "id_region").show
                ? "error"
                : ""
            }
            errors={getErrorField(forms.formState.errors, "id_region")}
          />
        </div>
      </Modal>
    </Card>
  );
};

export default DataUser;
