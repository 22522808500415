import { LayoutContext } from "context/LayoutContext";
import React, { useContext, useEffect, useState } from "react";
import AccordionTransaction from "./_components/accordion-trx";
import { getDetailKpiCustomer } from "apis/detail_kpi_customer";
import { useParams } from "react-router-dom";
import InputField from "components/fields/InputField";
import { debounce } from "helper/index";
import moment from "moment";

const Index = () => {
  const { setHeaderComponent } = useContext(LayoutContext);
  const [loading, setLoading] = useState(true);
  const [productName, setProductName] = useState("");
  const [info, setInfo] = useState(null);

  const { id } = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setHeaderComponent([]), []);

  useEffect(() => {
    (async () => {
      try {
        let query = "";
        if (productName) query = "?product_name=" + productName;

        const resp = await getDetailKpiCustomer(
          {
            id,
          },
          query
        );

        setInfo(resp);
      } catch (error) {
        console.error("Error when fetch : ", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 700);
      }
    })();
  }, [id, productName]);

  return (
    <>
      {loading ? (
        <div class="my-6 h-40 w-full animate-pulse rounded-xl bg-gray-200" />
      ) : (
        <div className="my-6 rounded-xl bg-white p-6 shadow-sm">
          <div className="flex items-start justify-between">
            <div className="flex items-start gap-6">
              <div>
                <div className="text-sm text-gray-600">
                  {info?.phone || "-"}
                </div>
                <h1 className="mt-1 text-xl font-semibold">
                  {info?.customer_name}
                </h1>
                <div className="mt-1 text-sm text-gray-600">
                  {info?.alamat || "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading ? (
        <div class="h-40 w-full animate-pulse rounded-xl bg-gray-200" />
      ) : (
        <>
          <h2 className="mb-2 text-lg font-semibold">List Produk</h2>
          <InputField
            label=""
            placeholder="Cari produk"
            className="mb-3 !bg-white"
            onChange={debounce((e) => {
              setProductName(e.target.value);
            }, 700)}
          />
          <div className="flex flex-col gap-2">
            {info?.list_product?.map((act) => (
              <div
                className="rounded-xl bg-white p-6 shadow-sm"
                key={act.id_product}
              >
                <div className="mb-6 flex items-start justify-between">
                  <div>
                    <h2 className="text-xl font-bold">{act.product_name}</h2>
                    <div className="text-sm text-gray-600">
                      Latest Update :{" "}
                      <b className="text-gray-800">
                        {moment(act.latest_update_at).format(
                          "DD MMMM YYYY HH:mm"
                        )}
                      </b>
                    </div>
                  </div>
                  <div className="mt-2">
                    <span className="rounded-full bg-blue-500 px-3 py-1 text-sm text-white">
                      Latest Stock : {act.latest_stock}
                    </span>
                  </div>
                </div>

                <div className="border-t pt-4">
                  <AccordionTransaction data={act.history_activity} />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default Index;
