import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import { getUser } from 'helper/localstorage';
import routes from './routes';
import NotFound from 'views/404';
import { useNavigate } from 'react-router-dom';
const App = () => {
    const location = useLocation();
    const currentUser = getUser();
    const navigate = useNavigate()

    useEffect(() => {
        const whiteListRedirectNotFound = ['/auth/sign-in', '/not-found'];
        const whiteListRedirectAuth = ['/auth', '/auth/sign-in'];
        const isRouteExist = routes.some((x) => {
            if (`${x.layout}/${x.path}` === location.pathname) {
                return true;
            }
        
            if (x.child) {
                return x.child.some((y) => {
                    if (!y.path.includes(":")) {
                        return `${y.layout}/${y.path}` === location.pathname;
                    } else {
                        const pathPattern = y.path.replace(/:\w+/g, "[^/]+");
                        const regex = new RegExp(`^${y.layout}/${pathPattern}$`);
                        return regex.test(location.pathname);
                    }
                });
            }
        
            return false;
        });
        if (location.pathname === '/') {
            if (currentUser) {
                navigate('/admin/dashboard');
            } else {
                navigate('/auth/sign-in');
            }
        } else if (
            !currentUser &&
            isRouteExist &&
            !whiteListRedirectNotFound.includes(location.pathname)
        ) {
            navigate('/auth/sign-in');
        } else if (!isRouteExist && location.pathname !== '/not-found') {
            navigate('/not-found');
        } else if (
            whiteListRedirectAuth.includes(location.pathname) &&
            currentUser
        ) {
            navigate('/admin/dashboard');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    return (
        <Routes>
            <Route path="auth/*" element={<AuthLayout />} />
            <Route path="admin/*" element={<AdminLayout />} />
            <Route path="not-found" element={<NotFound />} />
            <Route path="/" element={<Navigate to="/admin" replace />} />
        </Routes>
    );
};

export default App;
