import { useContext } from "react";
import { ContextHistoryStock } from "../context/ContextProvider";
import Text from "components/Text";
import ModalContainer from "components/Modal";

const ModalDetail = () => {
  const { open, setOpen, detailData } = useContext(ContextHistoryStock);

  return (
    <ModalContainer
      isOpen={open}
      modalTitle={`Detail Laporan Absensi`}
      onClose={() => {
        setOpen(false);
      }}
      hideButton
    >
      <div className="mb-3 flex w-[800px] flex-col gap-4 pr-2">
        {detailData?.products?.length > 0
          ? detailData?.products?.map((pr) => (
              <div
                className="flex w-full flex-col gap-4 rounded-xl border-[1px] px-4 py-5"
                key={pr.id_product}
              >
                <div className="flex w-full justify-between">
                  <Text variant="linkBold" content="Nama Produk" />
                  <Text variant="link" content={pr.product.product_name} />
                </div>
                <div className="flex w-full justify-between">
                  <Text variant="linkBold" content="Sisa Stok" />
                  <Text variant="link" content={pr.remaining_stock} />
                </div>
              </div>
            ))
          : "Tidak ada produk"}
      </div>
    </ModalContainer>
  );
};

export default ModalDetail;
