import MapView from "components/MapComponent";
import ModalContainer from "components/Modal";
import Text from "components/Text";
import { currency } from "helper/index";
import moment from "moment";
import React from "react";

const ModalDetailTransaction = ({ open, onClose, detailData }) => {
  return (
    <ModalContainer
      isOpen={open}
      modalTitle={`Detail Transaksi`}
      onClose={onClose}
      hideButton
    >
      <div className="mb-3 flex w-[800px] flex-col gap-4 pr-2">
        <div className="flex w-full flex-col items-center justify-center py-5">
          <div
            className={`mb-2 h-fit w-fit whitespace-nowrap rounded-full bg-[#F1F3F3] px-2 py-1 text-xs tracking-wide text-[#215759]`}
          >
            Uang Masuk
          </div>
          <Text variant="link" content="Total Tagihan" />
          <Text variant="h1" content={currency(detailData?.total)} />
        </div>

        <div className="flex w-full flex-col gap-4 rounded-xl border-[1px] px-4 py-5">
          <Text variant="h3" content="Detail Informasi" />
          <div className="border-b-[1px] border-[#E4E5E8]" />
          <div className="flex w-full justify-between">
            <Text variant="linkBold" content="Sales" />
            <Text
              variant="link"
              content={detailData?.user_sales?.sales_name ?? "-"}
            />
          </div>
          <div className="flex w-full justify-between">
            <Text variant="linkBold" content="Pelanggan" />
            <Text
              variant="link"
              content={detailData?.customer?.customer_name ?? "-"}
            />
          </div>
          <div className="flex w-full justify-between">
            <Text variant="linkBold" content="Waktu Pesanan" />
            <Text
              variant="link"
              content={moment(detailData?.created_at).format(
                "DD MMMM YYYY HH:mm"
              )}
            />
          </div>
        </div>

        <div className="flex w-full flex-col gap-4 rounded-xl border-[1px] px-4 py-5">
          <Text variant="h3" content="Pesanan" />
          <div className="border-b-[1px] border-[#E4E5E8]" />
          {detailData?.transaction_details?.map((item) => (
            <div className="flex w-full justify-between">
              <div className="w-1/5">
                <Text variant="linkBold" content={item.quantity} />
              </div>
              <div className="w-2/5">
                <Text variant="link" content={item.product.product_name} />
              </div>
              <div className="w-2/5 text-right">
                <Text variant="linkBold" content={currency(item.price)} />
              </div>
            </div>
          ))}
        </div>

        <div className="flex w-full flex-col gap-4 rounded-xl border-[1px] px-4 py-5">
          <Text variant="h3" content="Detail Tagihan" />
          <div className="border-b-[1px] border-[#E4E5E8]" />
          <div className="flex w-full justify-between">
            <Text variant="link" content="Subtotal" />
            <Text
              variant="linkBold"
              content={currency(
                detailData?.transaction_details?.reduce(
                  (acc, item) => acc + item.quantity * item.price,
                  0
                )
              )}
            />
          </div>
          <div className="border-b-[1px] border-[#E4E5E8]" />
          <div className="flex w-full justify-between">
            <Text variant="linkBold" content="Total Tagihan" />
            <Text variant="linkBold" content={currency(detailData?.total)} />
          </div>
        </div>

        <div className="flex w-full flex-col gap-4 rounded-xl border-[1px] px-4 py-5">
          <Text variant="h3" content="Pembayaran Tagihan" />
          <div className="border-b-[1px] border-[#E4E5E8]" />
          {detailData?.transaction_payments?.map((payment, index) => (
            <>
              <div className="flex w-full justify-between">
                <Text
                  variant="linkBold"
                  content={`Detail Pembayaran ${index + 1}`}
                />
                <Text
                  variant="link"
                  content={moment(payment.created_at).format(
                    "DD MMMM YYYY | HH:mm"
                  )}
                />
              </div>
              <div className="flex w-full justify-between">
                <Text variant="link" content="Payment Method" />
                <Text
                  variant="linkBold"
                  content={payment.payment_method_name}
                />
              </div>
              <div className="flex w-full justify-between">
                <Text variant="link" content="Total" />
                <Text
                  variant="linkBold"
                  content={currency(payment.payment_method_value)}
                />
              </div>
              <div className="border-b-[1px] border-[#E4E5E8]" />
            </>
          ))}

          <div className="flex w-full justify-between">
            <Text variant="linkBold" content="Sisa Tagihan" />
            <Text
              variant="linkBold"
              content={
                detailData?.transaction_payments?.reduce(
                  (acc, payment) => acc + payment.payment_method_value,
                  0
                ) > detailData?.total
                  ? currency(0)
                  : currency(
                      detailData?.total ??
                        0 -
                          detailData?.transaction_payments?.reduce(
                            (acc, payment) =>
                              acc + payment.payment_method_value,
                            0
                          ) ??
                        0
                    )
              }
            />
          </div>
        </div>

        <div className="flex w-full flex-col gap-4 rounded-xl border-[1px] px-4 py-5">
          <Text variant="h3" content="Lokasi Transaksi" />
          <div className="border-b-[1px] border-[#E4E5E8]" />
          <MapView
            latitude={detailData?.lat}
            longitude={detailData?.long}
            sales={detailData?.user_sales?.sales_name ?? "-"}
            customer={detailData?.customer?.customer_name ?? "-"}
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export default ModalDetailTransaction;
