import DefaultColumn from "components/Table/components/DefaultColumn";
import { MdRemoveRedEye } from "react-icons/md";

const TABLE_COLUMN = (onDetail) => [
  {
    accessorKey: "sales_name",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    header: "Nama Sales",
  },
  {
    accessorKey: "sales_role.role_name",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    header: "Role",
  },
  {
    accessorKey: "region.region_name",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    header: "Wilayah Penjualan",
  },
  {
    accessorKey: "action",
    header: "Aksi",
    size: 125,
    cell: (info) => (
      <button
        className="flex h-8 w-8 items-center justify-center rounded-lg bg-[#BEDBFE] text-[#3A65FF]"
        onClick={() => {
          onDetail(info.row.original.id_sales);
        }}
      >
        <MdRemoveRedEye />
      </button>
    ),
    enableSorting: false,
  },
];

export { TABLE_COLUMN };
