import httpService from "services/api.service";

const BASE_URL = "/detail_sales";

export const getDetailKpiSales = async (payload, query) => {
  try {
    const res = await httpService.get(`${BASE_URL}/${payload.id}${query}`);
    return res;
  } catch (error) {
    throw error.message;
  }
};
