import ActionColumn from "components/Table/components/ActionColumn";
import DefaultColumn from "components/Table/components/DefaultColumn";

const TableColumn = (onEdit, onDelete) => [
  {
    accessorKey: "username",
    header: "Email",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    size: 500,
  },
  {
    accessorKey: "role.role_name",
    header: "Role",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "action",
    header: "Aksi",
    size: 125,
    cell: (info) => (
      <ActionColumn onClickEdit={onEdit} onClickDelete={onDelete} {...info} />
    ),
    enableSorting: false,
  },
];

export { TableColumn };
