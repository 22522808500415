import DefaultColumn from "components/Table/components/DefaultColumn";
import { MdRemoveRedEye } from "react-icons/md";
import StatusColumn from "../component/StatusColumn";
import TypeColumn from "../component/TypeColumn";
import Button from "components/Button";
import moment from "moment";

const STATUS_ENUM = {
  DRAFT: 1,
  PROCESS: 2,
  SENT: 3,
  RECEIVED: 4,
  CANCEL: 5,
};

const TYPE_ENUM = {
  TRANSFER: 1,
  PURCHASE: 2,
};

const TABLE_COLUMN = ({ handleOpenDetail }) => [
  {
    accessorKey: "check_in_date",
    header: "Tanggal Check-in",
    cell: (info) => (
      <DefaultColumn>
        {moment(info.getValue()).format("DD MMM YYYY")}
      </DefaultColumn>
    ),
  },
  {
    accessorKey: "user_sale.sales_name",
    header: "Nama Sales",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
  },
  {
    accessorKey: "customer.customer_name",
    header: "Nama Toko",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
  },
  {
    accessorKey: "customer.customer_type",
    header: "Retail/Grosir",
    cell: (info) => (
      <DefaultColumn>
        {info.getValue() === 1 ? "Retail" : "Grosir"}
      </DefaultColumn>
    ),
  },
  {
    accessorKey: "products",
    header: "Sisa Stock pada Toko",
    cell: (info) => (
      <DefaultColumn>
        {(info.getValue() ?? []).reduce(
          (prev, curr) => prev + curr.remaining_stock,
          0
        )}
      </DefaultColumn>
    ),
  },
  {
    accessorKey: "is_selling",
    header: "Pernah Jual Ya/Tidak",
    cell: (info) => (
      <DefaultColumn>{info.getValue() ? "Ya" : "Tidak"}</DefaultColumn>
    ),
  },
  {
    accessorKey: "image_url",
    header: "Bukti Foto Checkin",
    cell: (info) => (
      <DefaultColumn>
        {info.getValue() ? (
          <img
            src={info.getValue()}
            alt="absensi"
            width="100px"
            height="100x"
            className="rounded-xl object-cover"
          />
        ) : (
          "-"
        )}
      </DefaultColumn>
    ),
  },
  {
    accessorKey: "action",
    header: "Aksi",
    size: 70,
    cell: (info) => (
      <div className="flex gap-1">
        <button
          className="flex h-8 w-8 items-center justify-center rounded-lg bg-[#BEDBFE] text-[#3A65FF]"
          onClick={() => {
            handleOpenDetail(info.row.original);
          }}
        >
          <MdRemoveRedEye />
        </button>
      </div>
    ),
  },
];

const TABLE_COLUMN_DETAIL = ({ setOpenSN }) => [
  {
    accessorKey: "product.sku",
    header: "SKU",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
  },
  {
    accessorKey: "product.product_name",
    header: "Nama produk",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
  },
  {
    accessorKey: "product.category.category_name",
    header: "Kategori Produk",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
  },
  {
    accessorKey: "latest_stock",
    header: "Stok Sistem",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
  },
  // {
  //     accessorKey: 'snbn',
  //     header: 'Serial/Batch Number',
  //     cell: info => (
  //         <Button
  //             label="Lihat"
  //             variant="SECONDARY"
  //             onClickHandler={() => setOpenSN(true)}
  //             size="sm"
  //         />
  //     ),
  // },
];

const TABLE_COLUMN_DETAIL_SNBN = [
  {
    accessorKey: "order",
    header: "Urutan",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
  },
  {
    accessorKey: "sn",
    header: "Serial Number",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    size: 400,
  },
];

const DUMMY_DATA = [
  {
    id: 1,
    date: "01 Januari 2024",
    type: 1,
    no: "PO/220523/RQ7",
    created_by: "Dwi Setiawan",
    status: 0,
  },
  {
    id: 2,
    date: "01 Januari 2024",
    type: 2,
    no: "PO/220523/RQ7",
    created_by: "Dwi Setiawan",
    status: 1,
  },
  {
    id: 3,
    date: "01 Januari 2024",
    type: 2,
    no: "PO/220523/RQ7",
    created_by: "Dwi Setiawan",
    status: 2,
  },
  {
    id: 4,
    date: "01 Januari 2024",
    type: 1,
    no: "PO/220523/RQ7",
    created_by: "Dwi Setiawan",
    status: 3,
  },
];

const DUMMY_DATA_DETAIL = [
  {
    id: 1,
    sku: "SKU#12345",
    name: "Bubble Gum Ice Cream",
    stock: {
      system: 120,
      actual: 100,
    },
    remaining: 20,
    capital_price: "Rp 10.000",
    note: "-",
  },
];

const DUMMY_DATA_DETAIL_SNBN = [
  { id: 1, order: 1, sn: "ABC-1011E0001" },
  { id: 2, order: 2, sn: "ABC-2022E0002" },
];

export {
  TABLE_COLUMN,
  TABLE_COLUMN_DETAIL,
  DUMMY_DATA,
  STATUS_ENUM,
  TYPE_ENUM,
  DUMMY_DATA_DETAIL,
  TABLE_COLUMN_DETAIL_SNBN,
  DUMMY_DATA_DETAIL_SNBN,
};
